import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import MessageStatusLabel from '@aurora/shared-client/components/messages/MessageStatusLabel/MessageStatusLabel';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import type { CSSPropertiesWithVars } from '@aurora/shared-client/helpers/styles/CSSPropertiesWithVarsHelper';
import type {
  Article,
  IdeaTopicMessage,
  Message,
  OccasionTopicMessage,
  TopicMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { TextAlignment } from '@aurora/shared-types/texts/enums';
import type { ReactElement } from 'react';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import {
  isTeaserImagePresent,
  isTeaserVideoPresent
} from '../../../../helpers/messages/MessageHelper/MessageHelper';
import type { ItemType } from '../../../../types/enums';
import { MessageViewVariant } from '../../../../types/enums';
import type {
  MessageTeaserFragment,
  MessageViewFragment,
  OccasionTimeFragment
} from '../../../../types/graphql-types';
import type { ItemViewVariantFC } from '../../../entities/types';
import KudosButton from '../../../kudos/KudosButton/KudosButton';
import KudosCount from '../../../kudos/KudosCount/KudosCount';
import OccasionProgressBadge from '../../../occasions/OccasionProgressBadge/OccasionProgressBadge';
import OccasionAttendeeCount from '../../../occasions/OccasionAttendeeCount/OccasionAttendeeCount';
import OccasionLocationData from '../../../occasions/OccasionLocationData/OccasionLocationData';
import OccasionRsvpIndicator from '../../../occasions/OccasionRsvpIndicator/OccasionRsvpIndicator';
import OccasionTime from '../../../occasions/OccasionTime/OccasionTime';
import OccasionType from '../../../occasions/OccasionType/OccasionType';
import TagList from '../../../tags/TagList/TagList';
import useInView from '../../../useInView';
import MessageBody from '../../MessageBody/MessageBody';
import MessageLink from '../../MessageLink/MessageLink';
import MessagePreviewImage from '../../MessagePreviewImage/MessagePreviewImage';
import MessagePreviewVideo from '../../MessagePreviewVideo/MessagePreviewVideo';
import MessageRepliesCount from '../../MessageRepliesCount/MessageRepliesCount';
import MessageSolvedBadge from '../../MessageSolvedBadge/MessageSolvedBadge';
import MessageSubject from '../../MessageSubject/MessageSubject';
import MessageTeaser from '../../MessageTeaser/MessageTeaser';
import MessageTimeToRead from '../../MessageTimeToRead/MessageTimeToRead';
import MessageViewCount from '../../MessageViewCount/MessageViewCount';
import localStyles from './MessageViewCard.module.pcss';
import MessageViewCardByline from './MessageViewCardByline';
import MessageViewCardContext from './MessageViewCardContext';
import MessageViewCardTimeWrapper from './MessageViewCardTimeWrapper';
import {
  isOccasionImminentOrOngoing,
  occasionImminentOrOngoing
} from '../../../../helpers/util/OccasionTimeHelper';

/**
 * Message View - Card
 *
 * If the card width is less than 600px, it displays in portrait-orientation. While below 600px, using the prop
 * `useCenteredContent` provides additional styling.
 *
 * If the card width is 600px or greater, the card displays in landscape-orientation. Additionally, once the card width
 * reaches 800px or greater, additional styling is applied.
 *
 * @constructor
 * @author Adam Ayres, Jonathan Bridges, Willi Hyde
 */
const MessageViewCard: ItemViewVariantFC<
  Message,
  ItemType.MESSAGE,
  MessageViewVariant.CARD
> = props => {
  const {
    entity: message,
    className,
    usePreviewMedia,
    useSubtitle,
    subtitle,
    useSubject,
    subjectClassName,
    useSubjectLink,
    useBody,
    useNodeLink,
    useAuthorLogin,
    useCenteredContent,
    portraitClampBodyLines,
    landscapeClampBodyLines,
    clampSubjectLines,
    useKudosCount,
    useRepliesCount,
    useTimeToRead,
    useViewCount,
    useTags,
    useSolvedBadge,
    timeStampType,
    useAuthorRank,
    useClickableCard,
    useVideoPreview,
    textAlignment,
    useVoteComponent,
    useOccasionTime,
    useOccasionLocation,
    useAttendeeCount,
    useAttendanceIndicator,
    useMessageStatus,
    viewSize,
    useOccasionType,
    useOccasionBadge,
    useManualSortNav
  } = props;

  const tenant = useContext(TenantContext);
  const cx = useClassNameMapper(localStyles);
  const { labelsEnabled: isTaggingEnabledOnNode } = tenant.publicConfig;
  const { board } = message;
  const { teaser } = message as Article;

  const [inViewRef, inView] = useInView<HTMLElement>();

  const hasClampLines = portraitClampBodyLines || landscapeClampBodyLines;
  const previewClampNumber: number = portraitClampBodyLines === false ? 0 : portraitClampBodyLines;
  const landscapeClampNumber: number =
    landscapeClampBodyLines === false ? 0 : landscapeClampBodyLines;

  const clampSubjectClassName = clampSubjectLines
    ? `lia-g-clamp lia-g-clamp-${clampSubjectLines}`
    : '';

  const cardStylesContent: CSSPropertiesWithVars = {
    '--lia-mvc-clamp-portrait': previewClampNumber,
    '--lia-mvc-clamp-landscape': landscapeClampNumber
  };

  const occasionData = (message as OccasionTopicMessage)?.occasionData;
  const showOccasionBadge = useOccasionBadge && isOccasionImminentOrOngoing(occasionData);

  // Media Elements
  const renderCardImage = (): ReactElement => (
    <MessageLink
      message={message}
      className={cx('lia-message-img-wrap')}
      tabIndex={useSubjectLink && -1}
      useManualSortNav={useManualSortNav}
    >
      <MessagePreviewImage
        className={cx('lia-message-img')}
        message={message}
        fallback={(): JSX.Element => (
          <NodeIcon
            node={message.board}
            size={IconSize.PX_120}
            className={cx('lia-message-img')}
            useFrame
          />
        )}
      />
    </MessageLink>
  );

  /**
   * renders the preview thumbnail for video
   */
  const renderCardVideoThumbnail = (): ReactElement => (
    <MessageLink
      message={message}
      className={cx('lia-message-img-wrap')}
      tabIndex={useSubjectLink && -1}
      useManualSortNav={useManualSortNav}
    >
      <MessagePreviewVideo
        message={message}
        variant={MessageViewVariant.CARD}
        fallback={(): JSX.Element => (
          <NodeIcon
            node={message.board}
            size={IconSize.PX_120}
            className={cx('lia-message-img')}
            useFrame
          />
        )}
      />
    </MessageLink>
  );

  /**
   * renders appropriate media (image/video) preview.
   */
  const renderCardMedia = (): ReactElement => {
    //render teaser video if present and video preview allowed
    if (useVideoPreview && isTeaserVideoPresent(message)) {
      return renderCardVideoThumbnail();
    }

    //render teaser or cover image if present.
    if (isTeaserImagePresent(message)) {
      return renderCardImage();
    }

    //render video or image present in the body. If both are present, video preferred.
    return useVideoPreview && message?.videos?.totalCount > 0
      ? renderCardVideoThumbnail()
      : renderCardImage();
  };

  // Card Body Elements
  const renderSubtitle = (): ReactElement | React.FC<React.PropsWithChildren<unknown>> => {
    // subtitle can be of type string or type react functional component.
    return subtitle ? (
      typeof subtitle === 'string' ? (
        <span className={cx('lia-g-subheading lia-subtitle')} data-testid="cardSubtitle">
          {subtitle}
        </span>
      ) : (
        subtitle
      )
    ) : null;
  };

  const renderTimeToRead = (): ReactElement => (
    <MessageTimeToRead
      as="span"
      className={cx('lia-g-subheading lia-subtitle lia-g-mr-0')}
      message={message}
    />
  );

  const renderSolvedBadge = (): ReactElement => (
    <MessageSolvedBadge message={message} className={cx('lia-solved-badge')} />
  );

  const renderSubject = (): ReactElement => (
    <MessageSubject
      message={message}
      as="h4"
      className={cx('lia-subject', subjectClassName)}
      useLink={useSubjectLink}
      subjectLinkClassName={cx('lia-subject-link', clampSubjectClassName, {
        'stretched-link': useClickableCard
      })}
      useTitle
      useManualSortNav={useManualSortNav}
    />
  );

  const renderTeaser = (): ReactElement => {
    return (
      <MessageTeaser
        teaserContent={message as MessageTeaserFragment}
        className={cx('lia-body-content', {
          ['lia-g-clamp lia-has-clamp']: hasClampLines
        })}
      />
    );
  };

  const renderMessageBody = (): ReactElement => {
    return (
      <MessageBody
        message={message}
        className={cx('lia-body-content', {
          ['lia-g-clamp lia-has-clamp']: hasClampLines
        })}
        useSearchSnippet={false}
      />
    );
  };

  // If teaser is present render teaser otherwise render body
  const renderPreviewText = (): ReactElement => {
    return teaser && teaser.trim() ? renderTeaser() : renderMessageBody();
  };

  const renderTags = (): React.ReactElement => (
    <TagList className={cx('lia-tag-list')} message={message} />
  );

  // Card Footer elements
  const renderKudosCount = (): ReactElement => <KudosCount message={message} />;

  const renderViewsCount = (): ReactElement => <MessageViewCount message={message} />;

  const renderRepliesCount = (): ReactElement => <MessageRepliesCount message={message} />;

  const renderOccasionAttendeeCount = (): ReactElement => {
    return <OccasionAttendeeCount message={message} />;
  };

  const renderOccasionProgressBadge = (): ReactElement => {
    return (
      <OccasionProgressBadge occasionData={occasionData} statuses={occasionImminentOrOngoing} />
    );
  };

  const renderOccasionLocation = (): ReactElement => {
    return (
      <OccasionLocationData
        className={cx('text-break lia-byline-item')}
        occasion={message as OccasionTopicMessage}
      />
    );
  };

  const renderOccasionType = (): ReactElement => {
    return (
      <OccasionType occasionType={(message as OccasionTopicMessage)?.occasionData?.occasionType} />
    );
  };

  const renderOccasionTime = (): ReactElement => {
    return (
      <OccasionTime
        occasion={(message as OccasionTopicMessage)?.occasionData as OccasionTimeFragment}
        className={cx(`lia-occasion-item lia-card-item lia-byline-${viewSize}`)}
      />
    );
  };

  const renderOccasionRsvpIndicator = (): ReactElement => {
    return (
      <OccasionRsvpIndicator
        message={message}
        className={cx(`font-weight-bold lia-byline-item lia-byline-${viewSize}`)}
      />
    );
  };

  const renderVoteButton = (): ReactElement => (
    <KudosButton
      message={message}
      view={MessageViewVariant.CARD}
      kudo={message.hasGivenKudo}
      kudosSum={message.kudosSumWeight}
      revisionNum={message.revisionNum}
      messageType={(message as MessageViewFragment).__typename}
      className={cx('lia-g-mt-10')}
    />
  );

  /**
   * Renders the status of the message
   */
  function renderMessageStatus() {
    const messageStatus = (message as IdeaTopicMessage).status;
    return (
      message.board.conversationStyle === ConversationStyle.Idea &&
      useMessageStatus && (
        <div className={cx('lia-g-divider lia-byline-item')}>
          <MessageStatusLabel status={messageStatus} className={cx('lia-g-pl-0')} />
        </div>
      )
    );
  }

  const showTimeForReadingBlog =
    board?.conversationStyle === ConversationStyle.Blog && useTimeToRead;
  const isTopic: boolean = message.depth === 0;
  const showTags: boolean =
    isTaggingEnabledOnNode &&
    useTags &&
    ((message as TopicMessage).tags?.edges?.length > 0 || isTopic || inView);
  const useSubtitleFinal: boolean =
    useSubtitle && (typeof subtitle === 'function' || subtitle?.length > 0);
  const useSubheading: boolean =
    useSubtitleFinal || showTimeForReadingBlog || useSubject || useSolvedBadge;
  const useByline = useAuthorLogin || useNodeLink || useAuthorRank;
  const useCardBody =
    useSubheading ||
    useBody ||
    useByline ||
    timeStampType ||
    showTags ||
    useOccasionTime ||
    useAttendanceIndicator ||
    useOccasionType ||
    showOccasionBadge;
  const useCardFooter = useKudosCount || useRepliesCount || useViewCount || useAttendeeCount;

  // For portrait-orientation, the byline renders as the first element of the card body when useCenteredContent is true,
  // or the last element of the card body when isCentered is false.

  return (
    <MessageViewCardContext.Provider
      value={{
        message,
        ...props
      }}
    >
      <div className={cx('lia-card-wrap')} style={cardStylesContent}>
        <article
          ref={inViewRef}
          className={cx(className, 'lia-message lia-g-card')}
          data-testid="MessageViewCard"
        >
          {usePreviewMedia && renderCardMedia()}
          {useCardBody && (
            <section
              className={cx(
                'lia-body-wrap',
                { 'lia-is-centered': useCenteredContent },
                { 'lia-g-card-body-is-clickable': useClickableCard },
                { 'lia-g-card-left': !useCenteredContent && textAlignment === TextAlignment.LEFT },
                {
                  'lia-g-card-center': !useCenteredContent && textAlignment === TextAlignment.CENTER
                }
              )}
            >
              {useSubheading && (
                <div className={cx('lia-sub-heading')}>
                  {(useSubtitleFinal || showTimeForReadingBlog) && (
                    <div className={cx('lia-subtitle-wrap')}>
                      <>
                        {useSubtitleFinal && renderSubtitle()}
                        {showTimeForReadingBlog && renderTimeToRead()}
                      </>
                    </div>
                  )}
                  {(useSubject || useSolvedBadge) && (
                    <div className={cx('lia-subject-wrap')}>
                      {useSubject && renderSubject()}
                      {useSolvedBadge && renderSolvedBadge()}
                    </div>
                  )}
                </div>
              )}
              {showOccasionBadge && renderOccasionProgressBadge()}
              {useBody && renderPreviewText()}
              {(useSubheading || useBody) && (
                <aside className={cx('lia-auto-space')} aria-label={`MessageCard-${message.id}`} />
              )}
              {useByline && <MessageViewCardByline />}
              {timeStampType && (
                <MessageViewCardTimeWrapper
                  message={message}
                  timeStampType={props.timeStampType}
                  useMessageTimeLink={props.useMessageTimeLink}
                />
              )}
              {useOccasionLocation && renderOccasionLocation()}
              {useOccasionTime && renderOccasionTime()}
              {useAttendanceIndicator && renderOccasionRsvpIndicator()}
              {useOccasionType && renderOccasionType()}
              {useMessageStatus && renderMessageStatus()}
              {showTags && renderTags()}
              {useVoteComponent && renderVoteButton()}
            </section>
          )}
          {useCardFooter && (
            <section
              className={cx('lia-message-footer', {
                'lia-is-centered': useCenteredContent || textAlignment === TextAlignment.CENTER
              })}
              tabIndex={-1}
            >
              {useViewCount && renderViewsCount()}
              {useKudosCount && renderKudosCount()}
              {useAttendeeCount && renderOccasionAttendeeCount()}
              {useRepliesCount && renderRepliesCount()}
            </section>
          )}
        </article>
      </div>
    </MessageViewCardContext.Provider>
  );
};

export default MessageViewCard;
